import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "category": "Guides",
  "sort": 0,
  "title": "Guides for the Signatures API"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`PDF form filling`}</h2>
    <p>{`The Signatures API supports having users fill out PDF forms in the frontend which becomes part of the signed PDF.`}</p>
    <p><a parentName="p" {...{
        "href": "./pdf-form-filling"
      }}>{`Learn more about PDF form filling`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      